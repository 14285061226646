import { NativeCurrency, Token } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'
import { Box, Flex, Text } from '@pancakeswap/uikit'
import { Progress, Select } from 'antd'
import { CurrencyLogo } from 'components/Logo'
import styled from 'styled-components'
import CardInput from './CardInput'
import PercentSelectOption from './PercentSelectOption'

const BoxStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  min-width: 120px;
  height: 24px;
  background: #3485ff;
  border-radius: 4px;

  img,
  svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
`
const CardContent = styled.div`
  padding: 12px 12px 32px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
  }
  .line_space {
    width: 16px;
    height: 4px;
    background: #3485ff;
    margin: 0 16px;
  }

  .ant-progress {
    .ant-progress-inner {
      background-color: #ccc;
    }

    .ant-progress-text {
      color: #f4eeff;
    }
  }
`
const RightInputButton = styled.div`
  border-radius: 6px;
  border: unset;

  img {
    position: relative;
    top: 2px;
  }

  .ant-select {
    width: 120px;

    .ant-select-selector {
      background: #3485ff;
      padding: 4px 12px;
      height: 36px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-arrow {
      svg {
        fill: #000;
      }
    }
  }
`
export enum VIEW_CARD {
  LOCK = 1,
  PRESALE = 2,
}

export const calculatePrice = (val: number) => {
  if (val <= 500000) return { block: 1, price: 0.25 }
  if (val <= 1000000) return { block: 2, price: 0.3 }
  if (val <= 1500000) return { block: 3, price: 0.35 }
  if (val <= 2000000) return { block: 4, price: 0.4 }
  if (val <= 2500000) return { block: 5, price: 0.45 }
  if (val <= 3000000) return { block: 6, price: 0.5 }
  if (val <= 3500000) return { block: 7, price: 0.55 }
  if (val <= 4000000) return { block: 8, price: 0.6 }
  if (val <= 4500000) return { block: 9, price: 0.65 }

  return { block: 10, price: 0.7 }
}

interface Props {
  max?: number
  native?: NativeCurrency
  userInput?: string
  setUserInput?: (v: any) => void
  errorMess?: string
  onChangePercent?: (p: number) => void
  onSelectToken: (val: any) => void
  selectedToken: NativeCurrency | Token
  totalBought: number
}

const MAX_BTS_SELL = 10000000

const CardContentLockAndLoad = ({
  max,
  native,
  errorMess,
  userInput,
  setUserInput,
  onChangePercent,
  onSelectToken,
  selectedToken,
  totalBought,
}: Props) => {
  return (
    <CardContent>
      <Flex p={['0', , '24px']} justifyContent="center" flexDirection="column" alignItems="center">
        <Text fontSize="20px" bold mb="8px">
          Token Allocation
        </Text>
        <Flex alignItems="center" mb="24px">
          <BoxStyled>
            <CurrencyLogo currency={selectedToken} size="20px" />
            <Text style={{ whiteSpace: 'nowrap' }}>
              {calculatePrice(totalBought)?.price} {selectedToken?.symbol}
            </Text>
          </BoxStyled>
          <div className="line_space" />
          <BoxStyled>
            <Text style={{ whiteSpace: 'nowrap' }}>{/* {selectedToken?.symbol === 'BNB' ? 2240 : 10} BTS */}1 BTS</Text>
          </BoxStyled>
        </Flex>
        <Text fontSize="20px" bold mb="6px">
          Vesting Plan
        </Text>
        <Text fontSize="14px" color="#D2D2DB" mb="5px">
          TGE - 100 %
        </Text>

        <Flex flexDirection="column" alignItems="center" width="100%">
          <Text fontSize={['18px', , '20px']} bold mb="6px" textAlign="center">
            Total ({(MAX_BTS_SELL - (+totalBought || 0)).toLocaleString()} BTS)
          </Text>
          <Progress
            percent={(totalBought / MAX_BTS_SELL) * 100}
            format={(val) => {
              return `${+val?.toFixed(2)}%`
            }}
          />

          <Text fontSize="14px" textAlign="center">
            {/* Block ${calculatePrice(totalBought)?.block}: Sell 500000 BTS price ${calculatePrice(totalBought)?.price}$ */}
            Block 2: Sell 5000000 BTS price $0.7
          </Text>
        </Flex>
      </Flex>
      <Box mt="24px" mb="24px">
        <CardInput
          value={userInput}
          labelLeft="Payment"
          labelRight={`Max: ${max ? max.toString().padStart(2, '0') : '-'} ${selectedToken?.symbol}`}
          errorMess={errorMess}
          rightInput={
            <RightInputButton>
              <Select
                options={[
                  // {
                  //   label: (
                  //     <Flex>
                  //       <CurrencyLogo currency={native} size="20px" />
                  //       <Text ml="4px">{native?.symbol}</Text>
                  //     </Flex>
                  //   ),
                  //   value: 'BNB',
                  // },
                  {
                    label: (
                      <Flex>
                        <CurrencyLogo currency={bscTokens?.usdt} size="20px" />
                        <Text ml="4px"> {bscTokens?.usdt?.symbol}</Text>
                      </Flex>
                    ),
                    value: 'USDT',
                  },
                ]}
                onChange={onSelectToken}
                defaultValue="USDT"
                popupClassName="token-select"
              />
            </RightInputButton>
          }
          placeholder="0.00"
          onUserInput={setUserInput}
        />
      </Box>

      <PercentSelectOption onChangePercent={onChangePercent} />
    </CardContent>
  )
}

export default CardContentLockAndLoad
