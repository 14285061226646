import { useState, useEffect, useCallback } from 'react'
import { useSellPullContract } from 'hooks/useContract'

const useGetTotalSale = () => {
  const [data, setData] = useState<any>(0)
  const contractSellPull = useSellPullContract()

  const getData = useCallback(async () => {
    if (contractSellPull) {
      try {
        const result = await contractSellPull.TOTAl_SALE()

        if (result) setData(+result / 1e18)
      } catch (e) {
        console.error(e)
      }
    }
  }, [contractSellPull])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useGetTotalSale
