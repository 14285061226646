import { Table } from 'antd'
import styled from 'styled-components'

const TableBaseStyle = styled.div<{ tableHeader?: any }>`
  background: #fff;
  overflow: hidden;

  .ant-table {
    background: transparent;
    border: unset;

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-thead .ant-table-cell,
    .ant-table-tbody .ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead {
      .ant-table-cell {
        background: ${({ tableHeader }) => (tableHeader ? tableHeader.background : '')};
        padding: 8px 4px;

        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #363636;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        padding: 13px 4px;
      }
    }
  }
`

const TableBase = ({ className = '', ...props }) => {
  return (
    <TableBaseStyle className={className} {...props}>
      <Table {...props} />
    </TableBaseStyle>
  )
}

export default TableBase
