import { ChainId } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'
import { useToast } from '@pancakeswap/uikit'
import tryParseAmount from '@pancakeswap/utils/tryParseAmount'
import { Col, Row } from 'antd'
import BigNumber from 'bignumber.js'
import { ToastDescriptionWithTx } from 'components/Toast'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useApproveCallback } from 'hooks/useApproveCallback'
import useCatchTxError from 'hooks/useCatchTxError'
import { useSellPullContract } from 'hooks/useContract'
import { useGetPreSaleList } from 'hooks/useGetPreSaleList'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance from 'hooks/useTokenBalance'
import { parseBigNumber, roundNumber } from 'library/helpers/Number'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { abbreviateHex, formatDate, getBlockExploreLink } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import { formatAmount } from 'utils/formatInfoNumbers'
import { useBalance } from 'wagmi'
import useGetPackages from '../hooks/useGetPackages'
import useGetTotalSale from '../hooks/useGetTotalSale'
import { useHistoryBuyPackagesByAccount } from '../hooks/useHistoryBuyPackages'
import useMinMaxBuy from '../hooks/useMinMaxBuy'
import CardContentLockAndLoad, { calculatePrice } from './CardContentLockAndLoad'
import CardContentPresale from './CardContentPresale'
import CardLeft, { VIEW_CARD } from './CardLeft'
import CardRight from './CardRight'
import HistoryTable from './HistoryTable'

const Wrapper = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-row-gap: 24px;
`

const Text = styled.p<{ status?: string }>`
  font-size: 14px;
  text-align: center;
  color: #000;
`

const PACKAGE_IDS = {
  BNB: 1,
  USDT: 2,
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdTime',
    render: (date) => <Text>{formatDate(+date * 1000, 'DD/MM/YYYY HH:mm', 'Etc/UTC')}</Text>,
  },
  {
    title: 'Address',
    dataIndex: 'userAddress',
    render: (data) =>
      data ? (
        <a
          href={getBlockExploreLink(data, 'address')}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'block', textAlign: 'center' }}
        >
          {abbreviateHex(data, 4)}
        </a>
      ) : (
        <Text>--</Text>
      ),
  },
  {
    title: 'BTS',
    dataIndex: 'amountToken',
    render: (volume) => <Text>{volume ? formatAmount(+volume / 1e18) : '--'}</Text>,
  },
  {
    title: 'USDT',
    dataIndex: 'amountBuy',
    render: (volume) => <Text>{volume ? formatAmount(+volume / 1e18) : '--'}</Text>,
  },
  {
    title: 'Hash',
    dataIndex: 'transactionHash',
    render: (hash) =>
      hash ? (
        <a
          href={getBlockExploreLink(hash, 'transaction')}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'block', textAlign: 'center' }}
        >
          {abbreviateHex(hash, 6)}
        </a>
      ) : (
        <Text>--</Text>
      ),
  },
]

const BuyPackageItem = (
  {
    pageSupportedChains,
    viewCardType,
  }: {
    pageSupportedChains: number[]
    viewCardType: VIEW_CARD
    style?: any
  },
  { ...props },
) => {
  const { toastSuccess } = useToast()
  const { account, chainId } = useActiveWeb3React()

  const [viewCard, setViewCard] = useState<VIEW_CARD>(viewCardType)
  const [userInput, setUserInput] = useState('')
  const [errorMess, setErrorMess] = useState('')
  const [selectedToken, setSelectedToken] = useState(null)

  const isBSC = chainId === ChainId.BSC

  const nativeBalance = useBalance({ addressOrName: account, enabled: !isBSC })
  const usdtBalance = useTokenBalance(bscTokens?.usdt?.address)
  const { data } = useBalance({ addressOrName: account, chainId: ChainId.BSC })

  /* contract */
  const contractSellPull = useSellPullContract()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()

  const [approvalState, approve, approveLoading] = useApproveCallback(
    tryParseAmount(userInput, bscTokens.usdt),
    contractSellPull?.address,
  )

  const native = useNativeCurrency()
  const { packages } = useGetPackages()
  const packageItem = useMemo(() => packages?.[viewCard - 1], [packages, viewCard])
  const { min, max } = useMinMaxBuy(selectedToken?.symbol)

  const [historyBuyPackages, fetchHistoryBuyPackages] = useHistoryBuyPackagesByAccount({
    account,
  })

  const [history] = useGetPreSaleList()

  // Total buy BTS
  const [totalBought, fetchTotalBought] = useGetTotalSale()

  /* Total was buy */
  const totalWasBuy = useMemo(() => {
    try {
      const totalBuy =
        historyBuyPackages &&
        historyBuyPackages.reduce((total, curr) => {
          const tt = total + parseBigNumber(curr.amountBuy)
          return tt
        }, 0)
      return roundNumber(totalBuy, { scale: 6 })
    } catch (error) {
      return 0
    }
  }, [historyBuyPackages])

  /* Max can buy */
  const maxBalanceCanBuy = useMemo(() => roundNumber(max - totalWasBuy, { scale: 4 }), [max, totalWasBuy])

  /* Handle change token */
  const handleSelectToken = (value) => {
    setUserInput('')
    if (value === 'BNB') {
      setSelectedToken(native)
      return
    }
    setSelectedToken(bscTokens?.usdt)
  }

  /* Handle option percent */
  const handleChangePercent = (percent) => {
    try {
      const balance =
        selectedToken?.symbol === 'USDT'
          ? +usdtBalance?.balance / 1e18
          : formatBigNumber(isBSC ? data?.value : nativeBalance?.data?.value)
      const valueByPercent = +((+balance * percent) / 100 - 0.005).toFixed(5)
      const maxBuy = valueByPercent > maxBalanceCanBuy ? maxBalanceCanBuy : valueByPercent
      setUserInput(roundNumber(maxBuy > 0 ? maxBuy : 0, { scale: 4 }).toString())
    } catch (error) {
      console.info(error)
    }
  }

  const detectError = (value, cbs) => {
    if (min === undefined || max === undefined) {
      setErrorMess('Error!')
      return
    }
    if (totalWasBuy === undefined) {
      setErrorMess('Error!')
      return
    }
    if (!value) {
      setErrorMess('Please enter amount')
      return
    }

    const nexTotalBuy = totalWasBuy + +value
    if (nexTotalBuy < min || nexTotalBuy > max) {
      setErrorMess(`Min: ${min} Max ${maxBalanceCanBuy} ${selectedToken?.symbol}`)
      return
    }

    const balance =
      selectedToken?.symbol === 'USDT'
        ? +usdtBalance?.balance / 1e18
        : formatBigNumber(isBSC ? data?.value : nativeBalance?.data?.value)

    if (+value > +balance) {
      setErrorMess(`Balance too low`)
      return
    }

    setErrorMess('')
    cbs?.()
  }

  // Buy now
  const handleConfirm = (cbs) => {
    detectError(userInput, async () => {
      const parseDecimals = new BigNumber(userInput).times(DEFAULT_TOKEN_DECIMAL).toString()
      const params = {
        fee: parseDecimals,
        packageId: PACKAGE_IDS[selectedToken?.symbol],
        amount: parseDecimals,
      }

      const receipt = await fetchWithCatchTxError(() => {
        return contractSellPull.buyTokenWithUSDT(params?.packageId, params?.amount, {
          gasPrice: '5000000000',
        })
      })

      // if (selectedToken?.symbol === 'USDT') {
      //   receipt = await fetchWithCatchTxError(() => {
      //     return contractSellPull.buyTokenWithUSDT(params?.packageId, params?.amount, {
      //       gasPrice: '5000000000',
      //     })
      //   })
      // } else {
      //   receipt = await fetchWithCatchTxError(() => {
      //     return contractSellPull.buyToken(params.packageId, params.amount, {
      //       value: params?.fee,
      //       gasPrice: '5000000000',
      //     })
      //   })
      // }

      if (receipt?.status) {
        setUserInput('')
        cbs()
        fetchTotalBought()
        fetchHistoryBuyPackages?.()
        toastSuccess(
          'Buy success',
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            Your funds have been success
          </ToastDescriptionWithTx>,
        )
      }
    })
  }

  const handleUserInput = (value) => {
    detectError(value, () => null)
    setUserInput(value)
  }

  const userOutput = useMemo(() => {
    if (selectedToken?.symbol === 'USDT') {
      return roundNumber(+userInput / calculatePrice(totalBought)?.price)
    }

    if (packageItem) {
      if (packageItem.price > 0) {
        return roundNumber(+userInput / packageItem.price)
      }
      return userInput
    }
    return undefined
  }, [packageItem, selectedToken?.symbol, totalBought, userInput])

  useEffect(() => {
    setSelectedToken(bscTokens?.usdt)
  }, [])

  return (
    <Wrapper {...props}>
      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CardLeft
            viewCardType={viewCard}
            setViewCard={setViewCard}
            renderContent={
              viewCard === VIEW_CARD.LOCK ? (
                <CardContentLockAndLoad
                  max={maxBalanceCanBuy}
                  native={native}
                  userInput={userInput}
                  setUserInput={handleUserInput}
                  errorMess={errorMess}
                  onChangePercent={handleChangePercent}
                  onSelectToken={handleSelectToken}
                  selectedToken={selectedToken}
                  totalBought={totalBought}
                />
              ) : (
                <CardContentPresale
                  max={maxBalanceCanBuy}
                  userInput={userInput}
                  setUserInput={handleUserInput}
                  errorMess={errorMess}
                  onChangePercent={handleChangePercent}
                />
              )
            }
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <CardRight
            account={account}
            onConfirm={handleConfirm}
            userOutput={userOutput}
            selectedToken={selectedToken}
            approvalState={approvalState}
            onApproveToken={approve}
            approveLoading={approveLoading}
          />
        </Col>
      </Row>

      <HistoryTable title="Buy History" columns={columns} data={history} />
    </Wrapper>
  )
}

export default BuyPackageItem
