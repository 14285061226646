import TableBase from 'components/Table/TableBase'
import styled from 'styled-components'

const TableWrapper = styled.div`
  padding: 24px 12px;
  box-shadow: 0px 0px 8px rgba(0, 52, 89, 0.16);

  background: #000000;
  border: 1px solid #3485ff;
  border-radius: 12px;

  overflow-x: scroll;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
  }

  &::-webkit-scrollbar {
    height: 6px;
  }

  .ant-table-cell-scrollbar {
    display: none;
  }
`

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
  }
`

const HistoryTable = ({ title, columns, data }) => {
  return (
    <TableWrapper>
      <TableHeader>
        <p>{title}</p>
      </TableHeader>
      <TableBase
        scroll={{ y: 500, x: 800 }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record?.id}
        tableHeader={{
          background: '#3485ff',
        }}
        pagination={!(data?.length <= 10)}
      />
    </TableWrapper>
  )
}

export default HistoryTable
