import { NativeCurrency, Token } from '@pancakeswap/sdk'
import { Box, Button, Flex, Text, useToast } from '@pancakeswap/uikit'
import BaseButton from 'components/BaseButton'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ApprovalState } from 'hooks/useApproveCallback'
import { useState } from 'react'
import styled from 'styled-components'
import CardInput from './CardInput'

const StyledCardRight = styled.div`
  padding: 24px 12px 12px;
  background: #000000;
  border: 1px solid #3485ff;
  border-radius: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 565px;
    padding: 24px;
  }
  .box_head {
    width: 100%;
    margin-bottom: 24px;
    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.sm} {
      height: 242px;
      margin-bottom: 70px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      margin-top: 40px;
    }

    svg {
      width: 100%;
      max-width: 160px;
      ${({ theme }) => theme.mediaQueries.sm} {
        max-width: 300px;
      }
    }
  }
`
const RightInputButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;

  width: 120px;
  height: 36px;

  background: #3485ff;
  border-radius: 8px;
  border: unset;

  img {
    width: 28px;
    height: 28px;
  }
`
const SubmitButtonStyled = styled(Button)`
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  width: 100%;
  max-width: 200px;
  height: 40px;
  margin: 0 auto;
  padding: 12px 48px;
  background: #3485ff;
  border-radius: 12px;
  border: unset;
  white-space: nowrap;
`

export enum VIEW_CARD {
  LOCK,
  PRESALE,
}

interface Props {
  account?: string
  onConfirm: (p: any, cb?: () => void) => void
  userOutput?: any
  selectedToken: NativeCurrency | Token
  approvalState: ApprovalState
  onApproveToken: () => Promise<void>
  approveLoading: boolean
}

const CardRight = ({
  account,
  onConfirm,
  userOutput,
  selectedToken,
  approvalState,
  onApproveToken,
  approveLoading,
}: Props) => {
  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    if (loading) return

    // setLoading(true)
    onConfirm(() => {
      // setLoading(false)
    })
  }

  return (
    <StyledCardRight>
      <div className="box_head">
        <Flex width="100%" justifyContent="center" mb="16px">
          <img src="/images/buy-token/banner.png?v1" alt="" />
        </Flex>
      </div>

      <Box mb="24px">
        <CardInput
          value={userOutput}
          readOnly
          labelLeft="Amount Received"
          rightInput={
            <RightInputButton>
              <img src="/images/buy-token/bts.svg" alt="" />
              <Text>BTS</Text>
            </RightInputButton>
          }
          placeholder="0.00"
        />
      </Box>
      <Flex justifyContent="center">
        {account ? (
          selectedToken?.symbol === 'BNB' || (selectedToken?.symbol === 'USDT' && approvalState === 3) ? (
            <BaseButton isLoading={loading} onClick={handleConfirm}>
              Buy token
            </BaseButton>
          ) : (
            <BaseButton disabled={approveLoading} onClick={onApproveToken}>
              Approve
            </BaseButton>
          )
        ) : (
          <ConnectWalletButton />
        )}
      </Flex>
    </StyledCardRight>
  )
}

export default CardRight
