import { useState, useEffect } from 'react'
import { useSellPullContract } from 'hooks/useContract'
import { formatBigNumber } from 'utils/formatBalance'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const METHOD_NAME_BY_TOKEN = {
  USDT: ['MIN_BUY_USDT', 'MAX_BUY_USDT'],
  BNB: ['MIN_BUY', 'MAX_BUY'],
}

const useMinMaxBuy = (tokenSymbol: string) => {
  const { chainId } = useActiveWeb3React()
  const [value, setValue] = useState({
    1: {
      min: undefined,
      max: undefined,
    },
    5: {
      min: undefined,
      max: undefined,
    },
    56: {
      min: undefined,
      max: undefined,
    },
    97: {
      min: undefined,
      max: undefined,
    },
  })

  const contractSellPull = useSellPullContract(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const [min, max] = await Promise.all([
          contractSellPull?.[METHOD_NAME_BY_TOKEN[tokenSymbol]?.[0]]?.(),
          contractSellPull?.[METHOD_NAME_BY_TOKEN[tokenSymbol]?.[1]]?.(),
        ])

        if (min && max) {
          setValue((prev) => ({
            ...prev,
            [chainId]: {
              min: +formatBigNumber(min),
              max: +formatBigNumber(max),
            },
          }))
        }
      } catch (e) {
        console.error('fetch min max error', e)
      }
    }

    if (!contractSellPull) return

    getData()
  }, [chainId, contractSellPull, tokenSymbol])

  return (
    value[chainId] || {
      min: undefined,
      max: undefined,
    }
  )
}

export default useMinMaxBuy
