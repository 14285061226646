import { gql } from 'graphql-request'
import { useCallback, useEffect, useState } from 'react'
import { graphqlBtsHoldingPreSale } from 'utils/graphql'

const PRE_SALE_QUERY = gql`
  query MyQuery($startTime: String) {
    buyPackages(where: { createdTime_gte: $startTime }, orderBy: "createdTime", orderDirection: "desc", first: 1000) {
      amountBuy
      amountToken
      createdTime
      id
      packageId
      transactionHash
      userAddress
    }
  }
`

export const getPreSaleList = async (): Promise<{ data?: any; error: boolean }> => {
  try {
    const data = await graphqlBtsHoldingPreSale.request(PRE_SALE_QUERY, {
      startTime: '1699574400',
    })

    return {
      data: data?.buyPackages,
      error: false,
    }
  } catch (error) {
    console.error('getTotalPackage', error)
    return { error: true }
  }
}

export const useGetPreSaleList = () => {
  const [dataList, setDataList] = useState<any>(null)
  const [amount, setAmount] = useState(0)

  const fetchData = useCallback(async () => {
    const { data } = await getPreSaleList()

    if (data) {
      const totalAmountToken = data?.reduce((total: number, cur: any) => {
        return total + +cur?.amountToken / 1e18
      }, 0)

      setAmount(totalAmountToken)
      setDataList(data)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return [dataList, amount, fetchData]
}
