import { useState } from 'react'
import styled from 'styled-components'

const StyledPercentSelectOption = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 14px;
  ${({ theme }) => theme.mediaQueries.lg} {
    gap: 20px;
  }
  button {
    width: 100%;
    height: 32px;
    background: #000;
    border-radius: 12px;
    border: 1px solid #3485ff;
    cursor: pointer;
    color: #fff;

    &:hover {
      background: #3485ff;
      color: #fff;
    }

    &.active {
      background: #3485ff;
    }

    :disabled {
      opacity: 0.7;
      background: #ddd;
      border: 1px solid #ddd;
      cursor: default;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 12px 24px;
      height: 43px;
    }
  }
`

interface Props {
  options?: number[]
  onChangePercent?: (p: number) => void
  disabled?: boolean
}

const defaultOptions = [25, 50, 75, 100]

const PercentSelectOption: React.FC<Props> = ({
  options = defaultOptions,
  onChangePercent,
  disabled = false,
  ...props
}) => {
  const [select, setSelect] = useState(options[0])
  const handleSelect = (num) => {
    setSelect(num)
    onChangePercent?.(num)
  }
  return (
    <StyledPercentSelectOption {...props}>
      {options.map((num, index) => {
        if (index < options.length - 1) {
          return (
            <button
              key={num}
              className={select === num ? 'active' : ''}
              type="button"
              onClick={() => handleSelect(num)}
              disabled={disabled}
            >
              {num}%
            </button>
          )
        }
        return (
          <button
            key={num}
            className={select === num ? 'active' : ''}
            disabled={disabled}
            type="button"
            onClick={() => handleSelect(num)}
          >
            Max
          </button>
        )
      })}
    </StyledPercentSelectOption>
  )
}

export default PercentSelectOption
