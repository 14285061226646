import styled from 'styled-components'

import BuyPackageItem from './components/BuyPackageItem'
import { VIEW_CARD } from './components/CardLeft'

const StyledHome = styled.div``

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  return (
    <StyledHome>
      <BuyPackageItem pageSupportedChains={pageSupportedChains} viewCardType={VIEW_CARD.LOCK} />
      {/* <BuyPackageItem pageSupportedChains={pageSupportedChains} viewCardType={VIEW_CARD.PRESALE} /> */}
    </StyledHome>
  )
}

export default Home
