/* eslint-disable react/default-props-match-prop-types */
import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'

const Wrapper = styled.button<{ background?: string }>`
  padding: 18px 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #3485FF;
  position: relative;
  font-size: 14px;
  z-index: 2;

  ${(props) =>
    props.background &&
    `
    background: ${props.background};
  `}

  &:disabled {
    filter: grayscale(100%);
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    color: #000;
    font-family: 'Poppins';
    font-size: inherit;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    text-transform: capitalize;
  }

  &[data-hover='true'],
  &[data-loading='true'] {
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[disabled],
  &[disabled='true'] {
    cursor: not-allowed;
  }

  img {
    width: 40px;
  }
`

const BaseButton: React.FC<any> = ({
  className,
  loading,
  disabled,
  background,
  onClick,
  disableHover,
  children,
  ...props
}) => {
  return (
    <Box className={className} position="relative">
      <Wrapper
        type="button"
        background={background}
        data-loading={loading.toString()}
        data-hover={disableHover.toString()}
        disabled={disabled}
        onClick={() => {
          if (!loading && !disabled && onClick) onClick()
        }}
        {...props}
      >
        <div>{children}</div>
        {loading && <Spin spinning />}
      </Wrapper>
    </Box>
  )
}

BaseButton.defaultProps = {
  loading: false,
  disabled: false,
  disableHover: false,
}

export default BaseButton
